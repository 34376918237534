var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c(_setup.AppPage,{attrs:{"title":"Applications"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('h4',{staticClass:"font-weight-regular mt-1",staticStyle:{"color":"#8c8c8c"}},[_vm._v(" View and manage all talent pool applications ")])]},proxy:true}])},[_c('section',{staticClass:"d-flex justify-between align-center"},[_c('h3',{staticClass:"mt-1 mb-3"},[_vm._v("Applicants")]),_c('div',{staticClass:"d-flex mb-3"},[_c('span',{staticClass:"px-3 py-1 grey--text"},[_vm._v("View")]),_c('square-icon-button',{staticClass:"mr-2",attrs:{"to":{
            name: _vm.$routes.TalentApplications,
          },"icon":"la-table","iconColor":"rgb(88, 88, 88)","title":"Table View"}}),_c('square-icon-button',{attrs:{"to":{
            name: _vm.$routes.TalentApplicationsSplitView,
          },"icon":"la-columns","color":"#007cff","iconColor":"white","title":"Split View"}})],1)]),_c('section',{staticClass:"d-flex flex-col"},[_c('div',{staticStyle:{"position":"relative","display":"flex"}},[_c('div',{staticClass:"d-flex flex-column applicant-list"},[_c('div',{staticClass:"header d-flex align-center mb-2"},[_c('filter-bar',{attrs:{"flat":true,"searchFilters":[
                {
                  field: 'nameContains',
                  label: 'Applicant',
                  expanded: true,
                  searchOnChange: true,
                  block: true,
                },
              ],"listFilters":[
                {
                  field: 'state',
                  label: 'Show',
                  filters: {
                    applying: 'Applying',
                    completed: 'Completed',
                    shortlisted: 'Shortlisted',
                  },
                },
              ],"dateFilters":[
                {
                  field: 'createdAtDate',
                  label: 'Applied',
                },
              ]},scopedSlots:_vm._u([{key:"append-filters",fn:function(){return [_c('div',[_c('TextButton',{staticStyle:{"text-transform":"capitalize"},on:{"click":_setup.onSelectApplicants}},[_vm._v(" "+_vm._s(_setup.showSelectApplicants ? "Cancel" : "Select")+" ")])],1)]},proxy:true}]),model:{value:(_setup.filter),callback:function ($$v) {_setup.filter=$$v},expression:"filter"}})],1),(_setup.loading)?_c('Loader',{attrs:{"text":"Loading applicants..."}}):[_c('div',{staticClass:"scrollable--content"},[(_setup.applicants?.length)?_c('v-list',{staticClass:"p-0",attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"active-class":"active"},model:{value:(_setup.selectedApplicantId),callback:function ($$v) {_setup.selectedApplicantId=$$v},expression:"selectedApplicantId"}},_vm._l((_setup.applicants),function(applicant,index){return _c('div',{key:applicant.id,class:{ active: applicant.scatterId === _setup.selectedApplicantId }},[_c('v-list-item',{on:{"click":function($event){return _setup.setSelectedApplicant(applicant)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_setup.showSelectApplicants)?[_c(_setup.Checkbox,{staticClass:"mr-2",attrs:{"value":applicant.scatterId},on:{"change":_setup.onSelectedApplicantsChange,"click":function($event){$event.stopPropagation();}}})]:_vm._e(),_c('v-list-item-avatar',[_c(_setup.UserAvatar,{attrs:{"size":40,"user":{
                              lastName: applicant?.lastName,
                              firstName: applicant?.firstName,
                            }}})],1),_c('v-list-item-content',{staticClass:"ml-3"},[(applicant.firstName || applicant.lastName)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(applicant?.firstName + " " + applicant?.lastName)+" ")]):_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" Unknown ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(applicant.jobRole)+" ")])],1)]},proxy:true}],null,true)}),(index < _setup.applicants.length - 1)?_c('v-divider',{key:index,staticClass:"m-0"}):_vm._e()],1)}),0)],1):_c('FeedBackMessage',{staticClass:"mt-2",attrs:{"icon":"la-users\n                ","resource":"applicants","preset":"emptyState"}})],1)],_c('div',{staticStyle:{"margin-top":"auto"}},[_c('div',{staticStyle:{"width":"95%"}},[_c(_setup.Pagination,{attrs:{"inline":false,"resource":"Applicants"},model:{value:(_setup.pagination),callback:function ($$v) {_setup.pagination=$$v},expression:"pagination"}})],1)])],2),_c('div',{staticClass:"pl-2",staticStyle:{"width":"70%"}},[(_setup.selectedApplicantIds.length > 1)?[_c(_setup.ManageBulkApplicantActions,{attrs:{"applicantIds":_setup.selectedApplicantIds},on:{"clearApplicants":_setup.clearSelectedApplicantIds}})]:[(_setup.selectedApplicant)?[_c(_setup.ApplicationDetail,{attrs:{"applicant":_setup.selectedApplicant,"refetchQueries":_setup.refetchQueries}})]:_c('div',[_c('FeedBackMessage',{staticClass:"mt-16",attrs:{"icon":"la-user","resource":"applicant details","preset":"emptyState","customMessage":"Select an applicant from the applicant list to view their details"}})],1)]],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }